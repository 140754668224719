// import Error from 'next/error';

import ErrorPage from '@/containers/common/error/Page';
import { useRouter } from 'next/router';

interface IProps {
    errorCode: number;
}

export async function getServerSideProps() {
    const errorCode = 404;
    return {
        props: { errorCode },
    };
}

export default function Page({ errorCode }: IProps) {
    
    const router = useRouter();
    const appleLoginRedirect = router.asPath;
    if (window.ReactNativeWebView)
        window.ReactNativeWebView.postMessage(JSON.stringify({ alert: appleLoginRedirect }));
    
    if (appleLoginRedirect.slice(0, 12) === '/login/apple') {
        router.push(router.asPath);
    };
    // alert(router.asPath);
    if (errorCode) {
        return <ErrorPage />;
    }

    return <ErrorPage />;
}
